import {
  BN,
  isNormalPositive,
  isValidAddress,
  num2Hex,
  parseUnit,
} from '@bifrost-platform/bifront-sdk-react-biholder';
import { useWallet } from '@bifrost-platform/bifront-sdk-react-wallet';
import { useCallback, useMemo } from 'react';
import DEFAULT_DEADLINE from '@/configs/defaultValue/defaultDeadline';
import DEFAULT_SLIPPAGE from '@/configs/defaultValue/defaultSlippage';
import {
  FRAG_EVERDEX_ADD_LIQUIDITY_STABLE,
  FRAG_EVERDEX_LP_REWARD_EXIT,
  FRAG_EVERDEX_LP_REWARD_GET_REWARD,
  FRAG_EVERDEX_LP_REWARD_STAKE,
  FRAG_EVERDEX_REMOVE_LIQUIDITY_STABLE,
} from '@/configs/fragment';
import useEnv from '@/hooks/useEnv';
import { getIsCorrectChain } from '@/hooks/useIsCorrectChain';
import { formatNumber } from '@/libs/formatNumber';
import log from '@/libs/log';
import toTimestamp from '@/libs/toTimestamp';
import useEverdexLpReward from './useEverdexLpReward';
import useEverdexLpTotalSupply from './useEverdexLpTotalSupply';
import useEverdexPool from './useEverdexPool';

const useEverdex = () => {
  // env
  const { chainBifrost, everdexLpRewardPool, addressSwapMerger } = useEnv();

  // wallet
  const { wallet, account } = useWallet();

  // lp total supply
  const {
    totalSupply: lpTotalSupply,
    isLoading: isLoadingLpTotalSupply,
    sync: syncLpTotalSupply,
  } = useEverdexLpTotalSupply();

  // pool
  const { pool, isLoading: isLoadingPool, sync: syncPool } = useEverdexPool();

  // lp reward
  const {
    balance: lpBalanceBn,
    rewardRate: lpRewardRate,
    apr: lpRewardApr,
    depositedAmount: depositedLpAmount,
    rewardAmount: lpRewardAmount,
    reserves: lpReserves,
    isLoading: isLoadingLpReward,
    sync: syncLpReward,
  } = useEverdexLpReward();

  // memo
  const lpBalance = useMemo(
    () =>
      isNormalPositive(lpBalanceBn, true) ? formatNumber(lpBalanceBn) : '0',
    [lpBalanceBn]
  );
  const poolApr = useMemo(
    () =>
      isNormalPositive(pool?.aprByAmount, true)
        ? formatNumber(new BN(pool?.aprByAmount ?? 0).multipliedBy(100))
        : '0',
    [pool?.aprByAmount]
  );
  const apr = useMemo(
    () =>
      formatNumber(
        new BN(isNormalPositive(lpRewardApr, true) ? lpRewardApr : 0).plus(
          isNormalPositive(poolApr, true) ? poolApr : 0
        )
      ),
    [poolApr, lpRewardApr]
  );
  const poolRate = useMemo(
    () =>
      isNormalPositive(lpTotalSupply, true)
        ? formatNumber(lpBalanceBn.div(lpTotalSupply ?? 0).multipliedBy(100))
        : '0',
    [lpBalanceBn, lpTotalSupply]
  );
  const poolAmounts = useMemo(
    () =>
      lpReserves.map((reserve) =>
        formatNumber(new BN(reserve).multipliedBy(new BN(poolRate).div(100)))
      ),
    [lpReserves, poolRate]
  );
  const isLoading = useMemo(
    () => isLoadingPool || isLoadingLpTotalSupply || isLoadingLpReward,
    [isLoadingLpReward, isLoadingLpTotalSupply, isLoadingPool]
  );

  // callback
  const addLiquidity = useCallback(
    async ({ amount }: { amount?: string }) => {
      log(
        'everdex:addLiquidity',
        amount,
        everdexLpRewardPool,
        addressSwapMerger
      );

      if (
        !(
          amount &&
          everdexLpRewardPool &&
          everdexLpRewardPool.tokens.length &&
          addressSwapMerger &&
          chainBifrost &&
          account &&
          isNormalPositive(amount, true) &&
          isValidAddress(everdexLpRewardPool.address) &&
          everdexLpRewardPool.tokens.reduce(
            (pre, token) => pre && isValidAddress(token.address),
            true
          ) &&
          isValidAddress(addressSwapMerger) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, chainBifrost.id)
        )
      ) {
        throw new Error('arguments error');
      }

      const slippage = DEFAULT_SLIPPAGE;
      const deadline = DEFAULT_DEADLINE;
      const { tokens } = everdexLpRewardPool;

      const desired = parseUnit(amount, tokens[0]?.decimals ?? 18);
      const amountMin = new BN(desired)
        .multipliedBy(
          new BN(1).minus(new BN(slippage).div(100)).multipliedBy(10000)
        )
        .div(10000);

      const addresses = tokens.map((token) => token.address);
      const tokenDesireds = [num2Hex(desired), '0x0'];
      const amountMins = [num2Hex(amountMin), '0x0'];

      const to = addressSwapMerger;
      const fragment = FRAG_EVERDEX_ADD_LIQUIDITY_STABLE;
      const values = [
        everdexLpRewardPool.address,
        addresses,
        tokenDesireds,
        amountMins,
        account,
        toTimestamp(deadline),
      ];

      return await wallet.send(to, fragment, values);
    },
    [account, addressSwapMerger, chainBifrost, everdexLpRewardPool, wallet]
  );
  const removeLiquidity = useCallback(
    async ({ amount, amounts }: { amount?: string; amounts?: string[] }) => {
      log(
        'everdex:removeLiquidity',
        amount,
        amounts,
        everdexLpRewardPool,
        addressSwapMerger
      );

      if (
        !(
          amount &&
          amounts &&
          everdexLpRewardPool &&
          everdexLpRewardPool.lpToken &&
          everdexLpRewardPool.tokens.length &&
          addressSwapMerger &&
          chainBifrost &&
          account &&
          isNormalPositive(amount, true) &&
          isValidAddress(everdexLpRewardPool.address) &&
          isValidAddress(everdexLpRewardPool.lpToken.address) &&
          everdexLpRewardPool.tokens.reduce(
            (pre, token, tokenIndex) =>
              pre &&
              isValidAddress(token.address) &&
              isNormalPositive(amounts[tokenIndex]),
            true
          ) &&
          isValidAddress(addressSwapMerger) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, chainBifrost.id)
        )
      ) {
        throw new Error('arguments error');
      }

      const slippage = DEFAULT_SLIPPAGE;
      const deadline = DEFAULT_DEADLINE;
      const { tokens } = everdexLpRewardPool;

      const amountMins = tokens.map((token, tokenIndex) => {
        const amount = parseUnit(amounts[tokenIndex], token.decimals ?? 18);
        const s1 = amount
          .multipliedBy(
            new BN(1).minus(new BN(slippage).div(100)).multipliedBy(10000)
          )
          .div(10000);

        return num2Hex(s1);
      });

      const to = addressSwapMerger;
      const fragment = FRAG_EVERDEX_REMOVE_LIQUIDITY_STABLE;
      const values = [
        everdexLpRewardPool.address,
        num2Hex(parseUnit(amount, everdexLpRewardPool.lpToken.decimals ?? 18)),
        amountMins,
        account,
        toTimestamp(deadline),
      ];

      return await wallet.send(to, fragment, values);
    },
    [account, addressSwapMerger, chainBifrost, everdexLpRewardPool, wallet]
  );
  const stakeLp = useCallback(
    async ({ amount }: { amount?: string }) => {
      log('everdex:stakeLp', amount, everdexLpRewardPool);

      if (
        !(
          amount &&
          everdexLpRewardPool &&
          everdexLpRewardPool.lpToken.address &&
          everdexLpRewardPool.rewardContract.proxyAddress &&
          chainBifrost &&
          account &&
          isNormalPositive(amount, true) &&
          isValidAddress(everdexLpRewardPool.lpToken.address) &&
          isValidAddress(everdexLpRewardPool.rewardContract.proxyAddress) &&
          isNormalPositive(chainBifrost.id, true) &&
          isValidAddress(account) &&
          getIsCorrectChain(wallet, chainBifrost.id)
        )
      ) {
        throw new Error('arguments error');
      }

      const to = everdexLpRewardPool.rewardContract.proxyAddress;
      const fragment = FRAG_EVERDEX_LP_REWARD_STAKE;
      const values = [
        num2Hex(parseUnit(amount, everdexLpRewardPool.lpToken.decimals ?? 18)),
      ];

      return await wallet.send(to, fragment, values);
    },
    [account, chainBifrost, everdexLpRewardPool, wallet]
  );
  const unstakeLp = useCallback(async () => {
    log('everdex:unstakeLp', everdexLpRewardPool);

    if (
      !(
        everdexLpRewardPool &&
        everdexLpRewardPool.rewardContract.proxyAddress &&
        chainBifrost &&
        account &&
        isValidAddress(everdexLpRewardPool.rewardContract.proxyAddress) &&
        isNormalPositive(chainBifrost.id, true) &&
        isValidAddress(account) &&
        getIsCorrectChain(wallet, chainBifrost.id)
      )
    ) {
      throw new Error('arguments error');
    }

    const to = everdexLpRewardPool.rewardContract.proxyAddress;
    const fragment = FRAG_EVERDEX_LP_REWARD_EXIT;

    return await wallet.send(to, fragment);
  }, [account, chainBifrost, everdexLpRewardPool, wallet]);
  const claimLp = useCallback(async () => {
    log('everdex:claimLp', everdexLpRewardPool);

    if (
      !(
        everdexLpRewardPool &&
        everdexLpRewardPool.rewardContract.proxyAddress &&
        chainBifrost &&
        account &&
        isValidAddress(everdexLpRewardPool.rewardContract.proxyAddress) &&
        isNormalPositive(chainBifrost.id, true) &&
        isValidAddress(account) &&
        getIsCorrectChain(wallet, chainBifrost.id)
      )
    ) {
      throw new Error('arguments error');
    }

    const to = everdexLpRewardPool.rewardContract.proxyAddress;
    const fragment = FRAG_EVERDEX_LP_REWARD_GET_REWARD;

    return await wallet.send(to, fragment);
  }, [account, chainBifrost, everdexLpRewardPool, wallet]);
  const sync = useCallback(async () => {
    try {
      await Promise.all([syncLpTotalSupply(), syncLpReward(), syncPool()]);
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncLpTotalSupply, syncLpReward, syncPool]);

  return {
    lpBalance,
    depositedLpAmount,
    lpRewardAmount,
    lpRewardApr,
    poolApr,
    apr,
    lpTotalSupply,
    lpRewardRate,
    pool,
    poolRate,
    poolAmounts,
    isLoading,
    addLiquidity,
    removeLiquidity,
    stakeLp,
    unstakeLp,
    claimLp,
    sync,
  };
};

export default useEverdex;
